import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { skip } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  BackendService,
  Headers,
  NotificationService,
  ResponsiveService,
  injectEnvironment,
} from '@cf/core';

import { LayoutService } from '@cf/core';
import {
  AccountService,
  AuthService,
  CalendarStoreService,
  FirstDayOfWeekEnum,
  IAccount,
  PaymentStoreService,
  SessionService,
  TIME_CONFIG,
  TimeFormatEnum,
  firstMonday,
  firstSaturday,
  firstSunday,
} from '@cf/temp/services';
import { ENotificationAction } from './modules/widgets/toast/notification-action';

@Component({
  selector: 'cf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'cal-frenzy';

  isAuthorized = !!this.sessionService.token;

  showSidenav = false;

  hideHeader!: boolean;

  private environment = injectEnvironment();
  appStartDate = new Date();

  constructor(
    private readonly notificationService: NotificationService,
    private readonly calendarStoreService: CalendarStoreService,
    private readonly paymentStoreService: PaymentStoreService,
    private readonly layoutService: LayoutService,
    private readonly responsiveService: ResponsiveService,
    private readonly swUpdate: SwUpdate,
    private readonly zone: NgZone,
    private readonly cd: ChangeDetectorRef,
    private readonly router: Router,
    private authService: AuthService,
    private sessionService: SessionService,
    accountService: AccountService,
    backendService: BackendService,
    modalConfig: NgbModalConfig,
  ) {
    this.prepareTimeConfig(accountService.account);
    if (this.environment.production) {
      this.checkNewVersion();
    }
    accountService.account$.pipe(skip(1)).subscribe((res) => {
      this.prepareTimeConfig(res);
      accountService.configUpdated$.next(true);
    });

    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
    modalConfig.centered = true;

    sessionService.state$.subscribe(() => {
      this.isAuthorized = !!sessionService.token;

      if (sessionService.token) {
        backendService.setHeader({
          [Headers.authorization]: `Bearer ${sessionService.token}`,
        });
      } else {
        backendService.removeHeader(Headers.authorization);
      }

      if (sessionService.token) {
        accountService.getAccountProfile();
      }
    });
  }

  ngOnInit() {
    if (this.authService.isAuthorized()) {
      this.calendarStoreService.getCalendars();
      this.paymentStoreService.getPaymentList();

      this.paymentStoreService.broadcastChannel.onmessage = () => {
        this.paymentStoreService.getPaymentList();
      };
    }

    this.responsiveService.isMobile$.subscribe((isMobile) => {
      if (!isMobile) {
        this.showSidenav = false;
      }
    });

    this.layoutService.hideHeader.subscribe((value) => {
      this.hideHeader = value;
      this.cd.detectChanges();
    });
  }

  login(): void {
    this.router
      .navigateByUrl('/')
      .then(() =>
        setTimeout(() => void this.router.navigate(['/auth/login']), 0),
      );
  }

  register(): void {
    this.router
      .navigateByUrl('/')
      .then(() =>
        setTimeout(() => void this.router.navigate(['/auth/sign-up']), 0),
      );
  }

  logout(): void {
    this.authService.logout();
  }

  private checkNewVersion() {
    self.addEventListener('install', (e: any) => {
      e.waitUntil(Promise.all([(self as any).skipWaiting()]).then());
    });
    self.addEventListener('activate', (e: any) => {
      e.waitUntil(Promise.all([(self as any).clients.claim()]));
    });

    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      )
      .subscribe(() => {
        const currentDate = new Date();
        const diff = currentDate.getTime() - this.appStartDate.getTime();
        // if the app was started less than 2 minutes ago, reload the page
        if (diff < 2 * 60 * 1000) {
          this.swUpdate.activateUpdate().then(() => {
            document.location.reload();
          });
        } else {
          this.showNotification();
        }
      });
  }

  private showNotification() {
    this.notificationService.action(
      'The new Calfrenzy version is available',
      '',
      ENotificationAction.RELOAD,
      false,
    );
  }

  // async checkExtension(id = 'igmlifilbgnlaijamohaakgoacoeoidk') {
  //   try {
  //     await fetch(`chrome-extension://${id}/index.html`);
  //     return true;
  //   } catch (e) {
  //     if (e instanceof TypeError && e.message == 'Failed to fetch') {
  //       return false;
  //     } else {
  //       throw e;
  //     }
  //   }
  // }

  private prepareTimeConfig(account: IAccount): void {
    this.zone.run(() => {
      if (account) {
        if (account.first_day_of_week === FirstDayOfWeekEnum.Sunday) {
          TIME_CONFIG.weeks = firstSunday;
        }
        if (account.first_day_of_week === FirstDayOfWeekEnum.Monday) {
          TIME_CONFIG.weeks = firstMonday;
        }
        if (account.first_day_of_week === FirstDayOfWeekEnum.Saturday) {
          TIME_CONFIG.weeks = firstSaturday;
        }
        if (account.timezone) {
          TIME_CONFIG.timezone = account.timezone;
        }
        if (account.time_format === TimeFormatEnum['12h']) {
          TIME_CONFIG.timeFormat = 12;
          TIME_CONFIG.defaultStartTime = '09:00 AM';
          TIME_CONFIG.defaultEndTime = '05:00 PM';
        } else {
          TIME_CONFIG.timeFormat = 24;
          TIME_CONFIG.defaultStartTime = '09:00';
          TIME_CONFIG.defaultEndTime = '17:00';
        }
      }
    });
  }
}
